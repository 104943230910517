/*---------------------------------------------------------------------------------------------
*
*  Repeater
*
*---------------------------------------------------------------------------------------------*/

.acf-repeater {
	
	/* table */
	> table {
		margin: 0 0 8px;
		background: #F9F9F9;

		> tbody tr.acf-divider:not(:first-child) > td {
			border-top: 10px solid $gray-200;
		}
	}
	
	/* row handle (add/remove) */
	.acf-row-handle {
		width: 16px;
		text-align: center !important;
		vertical-align: middle !important;
		position: relative;

		.acf-order-input-wrap {
			width: 45px;
		}

		.acf-order-input::-webkit-outer-spin-button,
		.acf-order-input::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		.acf-order-input {
			-moz-appearance: textfield;
			text-align: center;
		}

		/* icons */
		.acf-icon {
			display: none;
			position: absolute;
			top: 0;
			margin: -8px 0 0 -2px;
			
			
			/* minus icon */
			&.-minus {
				top: 50%;
				
				/* ie fix */
				body.browser-msie & { top: 25px; }
				
			}
		}
		
		
		/* .order */
		&.order {
			background: #f4f4f4;
			cursor: move;
			color: #aaa;
			text-shadow: #fff 0 1px 0;
			
			&:hover {
				color: #666;
			}
			
			+ td {
				border-left-color: #DFDFDF;
			}
		}

		&.pagination {
			cursor: auto;
		}
		
		/* remove */
		&.remove {
			background: #F9F9F9;
			border-left-color: #DFDFDF;
		}
	}
	
	
	/* add in spacer to th (force correct width) */
	th.acf-row-handle:before {
		content: "";
		width: 16px;
		display: block;
		height: 1px;
	}
	
	
	/* row */
	.acf-row {
		
		/* hide clone */
		&.acf-clone {
			display: none !important;
		}
		
		
		/* hover */
		&:hover,
		&.-hover {
			
			/* icons */
			> .acf-row-handle .acf-icon {
				display: block;

				// Show "duplicate" icon above "add" when holding "shift" key.
				&.show-on-shift {
					display: none;
					body.acf-keydown-shift & {
						display: block;
					}
				}
				&.hide-on-shift {
					body.acf-keydown-shift & {
						display: none;
					}
				}
			}
		}
	}

	/* sortable */
	> table > tbody > tr.ui-sortable-helper {
		box-shadow: 0 1px 5px rgba(0,0,0,0.2);
	}
	
	> table > tbody > tr.ui-sortable-placeholder {
		visibility: visible !important;
		
		td {
			background: #F9F9F9;
		}
	}
	
	
	/* layouts */
/*
	&.-row > table > tbody > tr:before,
	&.-block > table > tbody > tr:before {
		content: "";
		display: table-row;
		height: 2px;
		background: #f00;
	}
*/
	
	&.-row > table > tbody > tr > td,
	&.-block > table > tbody > tr > td {
		border-top-color: #E1E1E1;
	}
	
	
	/* empty */
	&.-empty > table > thead > tr > th {
		border-bottom: 0 none;
	}
	
	&.-empty.-row > table,
	&.-empty.-block > table {
		display: none;
	}
	
	
	/* collapsed */
	.acf-row.-collapsed {
		
		> .acf-field {
			display: none !important;
		}
		
		> td.acf-field.-collapsed-target {
			display: table-cell !important;
		}
	}
	
	/* collapsed (block layout) */
	.acf-row.-collapsed > .acf-fields {
		
		> * {
			display: none !important;
		}
		
		> .acf-field.-collapsed-target {
			display: block !important;
			
			&[data-width] {
				float: none !important;
				width: auto !important;
			}
		}
	}
	
	
	/* collapsed (table layout) */
	&.-table .acf-row.-collapsed .acf-field.-collapsed-target {
		border-left-color: #dfdfdf;
	}
	
	// Reached maximum rows.
	&.-max {
		
		// Hide icons to add rows.
		.acf-icon[data-event="add-row"] {
			display: none !important;
		}
	}

	.acf-actions {
		.acf-button {
			float: right;
			pointer-events: auto !important;
		}

		.acf-tablenav {
			float: right;
			margin-right: 20px;

			.current-page {
				width: auto !important;
			}
		}
	}
}

/*---------------------------------------------------------------------------------------------
*
*  Flexible Content
*
*---------------------------------------------------------------------------------------------*/

.acf-flexible-content {
	position: relative;
	
	// clones
	> .clones {
		display: none;
	}
	
	// values
	> .values {
		margin: 0 0 8px;
		
		// sortable
		> .ui-sortable-placeholder {
			visibility: visible !important;
			border: 1px dashed #b4b9be;
		
			box-shadow: none;
			background: transparent;
		}
	}
	
	// layout
	.layout {
		position: relative;
		margin: 20px 0 0;
	    background: #fff;
	    border: 1px solid $wp-card-border;
		
	    &:first-child {
			margin-top: 0;
		}
			
		// handle
		.acf-fc-layout-handle {
			display: block;
			position: relative;
			padding: 8px 10px;
			cursor: move;
			border-bottom: $wp-card-border solid 1px;
			color: #444;
			font-size: 14px;
			line-height: 1.4em;
		}
		
		// order
		.acf-fc-layout-order {
			display: block;
			width: 20px;
			height: 20px;
			border-radius: 10px;
			display: inline-block;
			text-align: center;
			line-height: 20px;
			margin: 0 2px 0 0;
			background: #F1F1F1;
			font-size: 12px;
			color: #444;
			
			html[dir="rtl"] & {
				float: right;
				margin-right: 0;
				margin-left: 5px;
			}
		}
		
		// controlls
		.acf-fc-layout-controls {
			position: absolute;
			top: 8px;
			right: 8px;
			
			.acf-icon {
				display: block;
				float: left;
				margin: 0 0 0 5px;
				
				&.-plus, &.-minus, &.-duplicate { visibility: hidden; }
			}
			
			html[dir="rtl"] & {
				right: auto;
				left: 9px;
			}
			
		}

		&.is-selected {
			border-color: $wp-input-border;
			.acf-fc-layout-handle  {
				border-color: $wp-input-border;
			}
		}
		
		// open
		&:hover, 
		&.-hover {
			
			// controls
			.acf-fc-layout-controls {
				
				.acf-icon {
					&.-plus, &.-minus, &.-duplicate { visibility: visible; }
				}
			}
		}
		
		// collapsed
		&.-collapsed {
			> .acf-fc-layout-handle {
				border-bottom-width: 0;
			}
			
			> .acf-fields,
			> .acf-table {
				display: none;
			}
		}
		
		// table
		> .acf-table {
			border: 0 none;
			box-shadow: none;
			
			> tbody > tr {
				background: #fff;
			}
			
			> thead > tr > th {
				background: #F9F9F9;
			}
		}
	}

	// no value
	.no-value-message {
		padding: 19px;
		border: #ccc dashed 2px;
		text-align: center;
		display: none;
	}

	// empty
	&.-empty > .no-value-message  {
		display: block;
	}
}

// popup
.acf-fc-popup {
	padding: 5px 0;
	z-index: 900001; // +1 higher than .acf-tooltip
	min-width: 135px;
	
	ul, li {
		list-style: none;
		display: block;
		margin: 0;
		padding: 0;
	}
	
	li {
		position: relative;
		float: none;
		white-space: nowrap;
	}
	
	.badge {
		display: inline-block;
		border-radius: 8px;
		font-size: 9px;
		line-height: 15px;
		padding: 0 5px;
		
		background: #d54e21;
		text-align: center;
		color: #fff;
		vertical-align: top;
		margin: 0 0 0 5px;
	}
	
	a {
		color: #eee;
		padding: 5px 10px;
		display: block;
		text-decoration: none;
		position: relative;
		
		&:hover {
			background: #0073aa;
			color: #fff;
		}
		
		&.disabled {
			color: #888;
			background: transparent;
		}
	}
}



/*---------------------------------------------------------------------------------------------
*
*  Galery
*
*---------------------------------------------------------------------------------------------*/

.acf-gallery {
	border: $wp-card-border solid 1px;
	height: 400px;
	position: relative;
	
	/* main */
	.acf-gallery-main {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: #fff;
		z-index: 2;
	}
	
	/* attachments */
	.acf-gallery-attachments {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 48px;
		left: 0;
		padding: 5px;
		overflow: auto;
		overflow-x: hidden;
	}
	
	
	/* attachment */
	.acf-gallery-attachment {
		width: 25%;
		float: left;
		cursor: pointer;
		position: relative;
		
		.margin {
			margin: 5px;
			border: $wp-card-border-1 solid 1px;
			position: relative;
			overflow: hidden;
			background: #eee;
			
			&:before {
				content: "";
			    display: block;
			    padding-top: 100%;
			}
		}
		
		.thumbnail {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			transform: translate(50%, 50%);
			
			html[dir="rtl"] & { 
				transform: translate(-50%, 50%);
			}
			
			img {
				display: block;
				height: auto;
				max-height: 100%;
				width: auto;
				transform: translate(-50%, -50%);
				
				html[dir="rtl"] & { 
					transform: translate(50%, -50%);
				}
			}
		}
		
		.filename {
		    position: absolute;
		    bottom: 0;
		    left: 0;
		    right: 0;
		    padding: 5%;
		    background: #F4F4F4;
		    background: rgba(255, 255, 255, 0.8);
		    border-top: #DFDFDF solid 1px;
		    font-weight: bold;
		    text-align: center;
		    word-wrap: break-word;
		    max-height: 90%;
		    overflow: hidden;
		}
		
		.actions {
			position: absolute;
			top: 0;
			right: 0;
			display: none;
		}
		
		
		/* hover */
		&:hover {
			
			.actions {
				display: block;
			}
			
		}
		
		
		/* sortable */
		&.ui-sortable-helper {
			
			.margin {
				border: none;
				box-shadow: 0 1px 3px rgba(0,0,0,0.3);
			}
		}
		
		&.ui-sortable-placeholder {
			
			.margin {
				background: #F1F1F1;
				border: none;
				
				* {
					display: none !important;
				}
			}
			
		}
		
		
		/* active */
		&.active {
			
			.margin {
				box-shadow: 0 0 0 1px #FFFFFF, 0 0 0 5px #0073aa;
			}
			
		}
		
		
		/* icon */
		&.-icon {
			
			.thumbnail img {
				transform: translate(-50%, -70%);
			}
			
		}
		
		
		/* rtl */
		html[dir="rtl"] & {
			float: right;
		}
		
	}
	
	
	
	&.sidebar-open {
		
		/* hide attachment actions when sidebar is open */
		.acf-gallery-attachment .actions {
			display: none;
		}
		
		
		/* allow sidebar to move over main for small widths (widget edit box) */
		.acf-gallery-side {
			z-index: 2;
		}
		
	}
	
	
	/* toolbar */
	.acf-gallery-toolbar {
		position: absolute;
		right: 0;
		bottom: 0;
		left: 0;
		padding: 10px;
		border-top: $wp-card-border-1 solid 1px;
		background: #fff;
		min-height: 28px;
		
		.acf-hl li {
			line-height: 24px;
		}
		
		.bulk-actions-select {
			width: auto;
			margin: 0 1px 0 0;
		}
		
	}
	
	
	/* sidebar */
	.acf-gallery-side {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		width: 0;
		
		background: #F9F9F9;
		border-left: $wp-card-border solid 1px;
		
		z-index: 1;
		overflow: hidden;
		
		.acf-gallery-side-inner {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			width: 349px;
		}
		
	}
	
	
	/* side info */
	.acf-gallery-side-info {
		@include clearfix();
		position: relative;
		width: 100%;
		padding: 10px;
		margin: -10px 0 15px -10px;
		background: #F1F1F1;
		border-bottom: #DFDFDF solid 1px;
		
		html[dir="rtl"] & {
			margin-left: 0;
			margin-right: -10px;
		}
	
		img {
			float: left;
			width: auto;
			max-width: 65px;
			max-height: 65px;
			margin: 0 10px 1px 0;
			background: #FFFFFF;
			padding: 3px;
			border: $wp-card-border solid 1px;
			border-radius: 1px;
			
			/* rtl */
			html[dir="rtl"] & {
				float: right;
				margin: 0 0 0 10px;
			}
		}
				
		p {
			font-size: 13px;
			line-height: 15px;
			margin: 3px 0;
			word-break: break-all;
			color: #666;
			
			strong {
				color: #000;
			}
		}
		
		a {
			text-decoration: none;
			
			&.acf-gallery-edit {
				color: #21759b;
			}
			
			&.acf-gallery-remove {
				color: #bc0b0b;
			}
			
			&:hover {
				text-decoration: underline;
			}
		
		}
		
	}
	
	
	/* side data */
	.acf-gallery-side-data {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 48px;
		left: 0;
		overflow: auto;
		overflow-x: inherit;
		padding: 10px;
	
		
		.acf-label,
		th.label {
			color: #666666;
			font-size: 12px;
			line-height: 25px;
			padding: 0 4px 8px 0 !important;
			width: auto !important;
			vertical-align: top;
			
			html[dir="rtl"] & { 
				padding: 0 0 8px 4px !important;
			}
			
			label {
				font-weight: normal;
			}
		}
				
		.acf-input,
		td.field {
			padding: 0 0 8px !important;
		}
		
		textarea {
			min-height: 0;
			height: 60px;
		}
		
		p.help {
			font-size: 12px;
			
			&:hover {
				font-weight: normal;
			}
		}
	
	}
	
	
	/* column widths */
	&[data-columns="1"] .acf-gallery-attachment { width: 100%; }
	&[data-columns="2"] .acf-gallery-attachment { width: 50%; }
	&[data-columns="3"] .acf-gallery-attachment { width: 33.333%; }
	&[data-columns="4"] .acf-gallery-attachment { width: 25%; }
	&[data-columns="5"] .acf-gallery-attachment { width: 20%; }
	&[data-columns="6"] .acf-gallery-attachment { width: 16.666%; }
	&[data-columns="7"] .acf-gallery-attachment { width: 14.285%; }
	&[data-columns="8"] .acf-gallery-attachment { width: 12.5%; }
	
	
	/* resizable */
	.ui-resizable-handle {
		display: block;
		position: absolute;
	}
	
	.ui-resizable-s {
		bottom: -5px;
		cursor: ns-resize;
		height: 7px;
		left: 0;
		width: 100%;
	}

}



/* media modal selected */
.acf-media-modal .attachment.acf-selected {
	box-shadow: 0 0 0 3px #fff inset, 0 0 0 7px #0073aa inset !important;
	
	.check {
		display: none !important;
	}
	
	.thumbnail {
		opacity: 0.25 !important;
	}
		
	.attachment-preview:before {
		background: rgba(0,0,0,0.15);
		z-index: 1;
		position: relative;
	}

}


.acf-admin-single-options-page {
	.select2-dropdown {
		border-color: $blue-300 !important;
		margin-top: -5px;
		overflow: hidden;
		box-shadow: $elevation-01;
	}

	.select2-dropdown.select2-dropdown--above {
		margin-top: 0;
	}

	.select2-container--default .select2-results__option[aria-selected="true"] {
		background-color: $gray-50 !important;
		color: $gray-500;

		&:hover {
			color: $blue-400;
		}
	}

	.select2-container--default
		.select2-results__option--highlighted[aria-selected] {
		color: #fff !important;
		background-color: $blue-500 !important;
	}

	// remove bottom margin on options
	.select2-dropdown .select2-results__option {
		margin-bottom: 0;
	}
}

// z-index helper for the popup modal.
.acf-create-options-page-popup ~ .select2-container {
	z-index: 999999999;
}
